import {
  CheckboxField,
  InputField,
  Modal,
  Option,
  SelectField,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getDictionary } from 'services/dictionary';
import { Dictionary, DictionaryPayload } from 'types/dictionary';
import { Page } from 'types/request';

const validationSchema = Yup.object().shape({
  value: Yup.string().required(requiredFieldMessage),
  hidden: Yup.boolean().required(requiredFieldMessage),
});

const validationSchemaForBonus = Yup.object().shape({
  value: Yup.string().required(requiredFieldMessage),
  key: Yup.string().required(requiredFieldMessage),
  hidden: Yup.boolean().required(requiredFieldMessage),
});

type Props = {
  title: string;
  visible: boolean;
  editedItem?: Dictionary;
  canHandleHidden?: boolean;
  dictionaryError?: AxiosError | null;
  selectedType?: string | number;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSave: (dictionary: DictionaryPayload, officeId?: number) => void;
  closeModal: () => void;
};

const DictionaryForm = ({
  title,
  visible,
  editedItem,
  canHandleHidden,
  dictionaryError,
  selectedType,
  setVisible,
  onSave,
  closeModal,
}: Props) => {
  const [dictionaryType, setDictionaryType] = useState<string>();
  const { activeDictionaries } = useDictionaryContext();

  useEffect(() => {
    if (selectedType === 'BONUS_CODE_NAME') return setDictionaryType('BONUS_CODE_ACCOUNT');
    if (selectedType === 'MPK') return setDictionaryType('OFFICE');

    setDictionaryType(undefined);
  }, [visible, selectedType]);

  const showHidden = () => {
    return canHandleHidden === undefined ? true : canHandleHidden;
  };

  const { data: dictionaryBonusesCodes, error: dictionariesError } = useQuery<
    Page<Dictionary> | undefined,
    AxiosError
  >(
    ['DICTIONARY_BONUS'],
    () => {
      if (dictionaryType) return getDictionary(dictionaryType, 0, 10000);
    },
    { enabled: !!dictionaryType }
  );

  const dictionaryBonusCodeKeys: Option[] = useMemo(
    () => [
      { key: '', value: 'Dowolny' },
      ...(dictionaryBonusesCodes?.content?.map(({ key }) => ({ key, value: key })) || []),
    ],
    [dictionaryBonusesCodes]
  );

  const isAgreementType = selectedType === 'AGREEMENT_TYPE';
  const isBonusCodeAccount = selectedType === 'BONUS_CODE_ACCOUNT';
  const isG5CodeAccount = selectedType === 'G5_CODE_ACCOUNT';
  const isBonusCodeProductGroup = selectedType === 'BONUS_CODE_PRODUCT_GROUP';
  const currentValidationSchema = useMemo(
    () => (dictionaryType || isBonusCodeAccount ? validationSchemaForBonus : validationSchema),
    [selectedType]
  );
  const bonusCodeProductGroupOptions = useMemo(
    () => [
      ...(activeDictionaries
        ? activeDictionaries[DICTIONARY_TYPES.BONUS_CODE_NAME].map((item) => ({
            key: item.key,
            value: item.value,
          }))
        : []),
      {
        key: 'OTHER_PREMIUMS',
        value: 'Premie inne',
      },
    ],
    [activeDictionaries]
  );
  const bonusValueProductGroupOptions = useMemo(
    () =>
      activeDictionaries
        ? [
            ...activeDictionaries[DICTIONARY_TYPES.PRODUCT_GROUP],
            {
              key: 'OTHER_PREMIUMS',
              value: 'Premie inne',
            },
          ]
        : [],
    [activeDictionaries]
  );

  const handleSave = (payload: DictionaryPayload) => {
    if (selectedType === 'MPK') {
      const { key } = payload;
      const office = dictionaryBonusesCodes?.content?.find(
        ({ key: dictionaryKey }) => dictionaryKey === key
      );

      return onSave(payload, office?.id);
    }

    onSave(payload);
  };

  return (
    <Modal title={title} visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={{
          value: editedItem ? editedItem.value : '',
          hidden: editedItem ? editedItem.hidden : false,
          key: editedItem ? editedItem.key : '',
        }}
        onSubmit={handleSave}
        validationSchema={currentValidationSchema}>
        <Form className="space-y-4">
          {!isAgreementType && !isBonusCodeProductGroup && (
            <InputField name="value" label="Wartość pozycji" />
          )}

          {isAgreementType && (
            <>
              <SelectField
                options={activeDictionaries?.[DICTIONARY_TYPES.AGREEMENT_TYPE] || []}
                name="value"
                label="Wartość pozycji"
              />
              <InputField name="key" label="Kod pozycji" />
            </>
          )}

          {isBonusCodeProductGroup && (
            <>
              <SelectField
                name="value"
                label="Wartość pozycji"
                options={bonusValueProductGroupOptions}
              />
              <SelectField name="key" label="Kod pozycji" options={bonusCodeProductGroupOptions} />
            </>
          )}

          {dictionaryType && (
            <SelectField options={dictionaryBonusCodeKeys} name="key" label="Kod pozycji" />
          )}

          {(isBonusCodeAccount || isG5CodeAccount) && <InputField name="key" label="Kod pozycji" />}

          {showHidden() && <CheckboxField name="hidden">Ukryty</CheckboxField>}
          <ModalActions onCancel={closeModal} />

          {dictionaryError && <ErrorMessages error={dictionaryError} />}
          {dictionariesError && <ErrorMessages error={dictionaryError} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default DictionaryForm;
