import { Alert, Button, Modal } from '@profitowi/component-library';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CommissionImportForm from './CommissionImportForm/CommissionImportForm';
import CommissionImportPreview from './CommissionImportPreview/CommissionImportPreview';

type Props = {
  refetchImports: () => void;
};

const CommissionImportSteps = ({ refetchImports }: Props) => {
  const [commissionImportId, setCommissionImportId] = useState<number[]>();
  const [step, setStep] = useState(1);
  const [importRecordsLength, setImportRecordsLength] = useState<number>();

  const navigate = useNavigate();

  const closeModal = () => {
    refetchImports();
    setImportRecordsLength(undefined);
    navigate('..');
  };

  const renderSteps = (step: number) => {
    switch (step) {
      case 1:
        return (
          <CommissionImportForm setCommissionSchemaId={setCommissionImportId} setStep={setStep} />
        );
      case 2:
        return (
          <CommissionImportPreview
            refetchImports={refetchImports}
            setStep={setStep}
            setImportRecordsLength={setImportRecordsLength}
            commissionImportId={commissionImportId}
          />
        );
      case 3:
        return (
          <div className=" w-full space-y-4">
            <Alert type="success">
              Zaimportowano poprawnie z pliku {importRecordsLength ?? 0} wierszy
            </Alert>
            <div className="flex justify-end">
              <Button onPress={() => closeModal()}>Zakończ</Button>
            </div>
          </div>
        );
    }
  };

  return (
    <Modal visible size={step === 2 ? '7xl' : undefined} title="Dodaj źródło danych">
      {renderSteps(step)}
    </Modal>
  );
};

export default CommissionImportSteps;
