import { Box, Button, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMENTS } from 'constants/queries/comment';
import { usePagination } from 'hooks/usePagination';
import { getComments } from 'services/comment';
import { Comment } from 'types/comment';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import useAgentId from '../../../useAgentId';
import { columns } from './columns';
import AddCommentModal from './components/AddCommentModal/AddCommentModal';
import DeleteCommentModal from './components/DeleteCommentModal/DeleteCommentModal';

const Comments = () => {
  const navigate = useNavigate();
  const agentId = useAgentId();
  const pagination = usePagination(10);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([
    {
      id: 'createdAt',
      desc: true,
    },
  ]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, isFetching, isSuccess, isError, error, refetch } = useQuery<
    Page<Comment>,
    AxiosError
  >(
    [COMMENTS.COMMENT_LIST, agentId, currentPage, perPage, sortBy],
    () => {
      let customSortRule = undefined;
      if (sortBy[0]?.id === 'createdBy') {
        customSortRule = {
          id: 'createdAt',
          desc: true,
        };
      }
      return getComments(
        agentId,
        currentPage,
        perPage,
        decodeUriSortParams(sortBy, customSortRule)
      );
    },
    { keepPreviousData: true }
  );

  return (
    <>
      <div className="flex-1 space-y-6 overflow-hidden">
        <Box className="space-y-6 flex flex-col">
          <div className="flex justify-between items-center">
            <h4 className="text-lg font-semibold">Lista komentarzy</h4>
            <Button
              variant="outline-primary"
              isDisabled={isFetching}
              onPress={() => navigate('addComment')}>
              <Text weight="semibold">
                Nowy komentarz
                <i className="bi bi-plus-lg ml-2"></i>
              </Text>
            </Button>
          </div>

          {isSuccess && data && (
            <Box.FullWidth>
              <Table
                data={data.content || []}
                columns={columns}
                isLoading={isFetching}
                pagination={pagination}
                totalPages={data.totalPages}
                onSortBy={handleSortBy}
                sortBy={sortBy}
              />
            </Box.FullWidth>
          )}
          {isError && <ErrorMessages error={error} />}
        </Box>
      </div>

      <Routes>
        <Route path="addComment" element={<AddCommentModal refetch={refetch} />} />
        <Route path="deleteComment" element={<DeleteCommentModal refetch={refetch} />} />
      </Routes>
    </>
  );
};

export default Comments;
