import { Button } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useAgentRoleContext from 'hooks/useAgentRoleContext';
import { Options } from 'hooks/useDictionaryContext';
import { Roles, User, UserAction } from 'types/user';
import { parseActiveStatus } from 'utils/lingual';

export const createColumn = (
  translate: (dictionaryName: string, key: string, options?: Options) => string,
  setEditedItem: (user: User, action: UserAction) => void
): Column<User>[] => {
  return [
    {
      Header: 'Nazwa użytkownika',
      accessor: (row: User) => {
        const { username } = row ?? '';
        return username.toUpperCase();
      },
      disableSortBy: true,
    },
    {
      Header: 'Imię',
      accessor: 'firstName',
      disableSortBy: true,
    },
    {
      Header: 'Nazwisko',
      accessor: 'lastName',
      disableSortBy: true,
    },
    {
      Header: 'Mail',
      accessor: 'email',
      disableSortBy: true,
    },
    {
      Header: 'Role',
      disableSortBy: true,
      accessor: (row: User) => {
        const result: string[] = [];
        row.roles.forEach((role) => {
          result.push(translate(DICTIONARY_TYPES.KEYCLOAK_ROLES, role));
        });
        return result.join(', ');
      },
    },
    {
      Header: 'Status',
      disableSortBy: true,
      accessor: (row: User) => {
        return parseActiveStatus(row.enabled, 'HE');
      },
    },
    {
      id: 'edit',
      Header: 'Akcje',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<User>) => {
        const { isSuperAdminRole } = useAgentRoleContext();

        return (
          <div className="flex justify-end">
            {isSuperAdminRole && (
              <Button variant="light" onPress={() => setEditedItem(original, 'CHANGE_STATUS')}>
                {original.enabled ? 'Wyłącz' : 'Włącz'}
              </Button>
            )}
            <Button variant="light" onPress={() => setEditedItem(original, 'EDIT_DATA')}>
              Edytuj dane
            </Button>
            {(!original.roles.includes(Roles.SUPER_ADMIN) || isSuperAdminRole) && (
              <Button variant="light" onPress={() => setEditedItem(original, 'CHANGE_PASSWORD')}>
                Zmień hasło
              </Button>
            )}
            {isSuperAdminRole && (
              <Button variant="light" onPress={() => setEditedItem(original, 'EDIT_ROLES')}>
                Edytuj role
              </Button>
            )}
          </div>
        );
      },
    },
  ];
};
