import { AdvancePayment, AdvancePaymentPayload } from 'types/advancePayment';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getAdvancesForAgent = async (
  agentId: number,
  page: number,
  size: number,
  sort?: string
) => {
  const { data } = await recordmodApi.get<Page<AdvancePayment>>(`/agents/${agentId}/advances`, {
    params: { page, size, sort },
  });

  return data;
};

export const getAdvancesPaidSumForAgent = async (agentId: number) => {
  const { data } = await recordmodApi.get<string>(`/agents/${agentId}/advances/paid/sum`);
  return data;
};

export const getAdvancesInstallmentsToRepayAgent = async (agentId: number) => {
  const { data } = await recordmodApi.get<string>(
    `/agents/${agentId}/advances/installments/to-repay/sum`
  );
  return data;
};

export const createAdvanceForAgent = (agentId: number, payload: AdvancePaymentPayload) => {
  return recordmodApi.post(`/agents/${agentId}/advances`, payload);
};

export const updateAdvance = (
  agentId: number,
  payload: AdvancePaymentPayload,
  advanceId: number
) => {
  return recordmodApi.put(`/agents/${agentId}/advances/${advanceId}`, payload);
};

export const getAdvancePayment = async (agentId: number, advancePaymentId: number) => {
  const { data } = await recordmodApi.get<AdvancePayment>(
    `/agents/${agentId}/advances/${advancePaymentId}`
  );

  return data;
};

export const deleteAgentAdvance = (agentId: number, agentBonusId: number) => {
  return recordmodApi.delete(`/agents/${agentId}/advances/${agentBonusId}?cancelSettled=false`);
};

export const generatePayoutsAdvance = (agentId: number, advanceId: number) => {
  return recordmodApi.post(`/agents/${agentId}/payouts/advances/${advanceId}`);
};
