import { Box, Button, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { SETTLEMENT } from 'constants/queries/settlement';
import { usePagination } from 'hooks/usePagination';
import { getSettlements } from 'services/settlement';
import { Page } from 'types/request';
import { SettlementData, SettlementType } from 'types/settlement';
import { decodeUriSortParams } from 'utils/table';

import { Columns } from './columns';

const buttons = [
  {
    description:
      'Rozliczenie prowizji przejmie rozliczenie wszystkich aktywnych zdarzeń. Nie będzie można ująć jednego zdarzenia w dwóch rozliczeniach do czasu ewentualnego anulowania bądź zakończenia rozliczenia.',
    settlementType: SettlementType.COMMISSION_SETTLEMENT,
    title: 'Rozpocznij rozliczenie prowizji',
  },
  {
    description:
      'Rozliczenie sald uwzględni saldo Kontrahentów większe od 50 oraz dodatnie dla Kontrahentów, którzy zakończyli współpracę',
    settlementType: SettlementType.BALANCE_SETTLEMENT,
    title: 'Rozpocznij rozliczenie sald',
  },
  {
    description:
      'Rozliczenie przelewów uwzględni wszystkie Płatności, które nie zostały do tej pory zrealizowane poprzez wygenerowanie dokumentu z przelewami.',
    settlementType: SettlementType.TRANSFER_SETTLEMENT,
    title: 'Rozpocznij rozliczenie przelewów',
  },
  {
    description: 'Generowanie dokumentów zbiorczych dla danego miesiąca rozliczeniowego',
    settlementType: SettlementType.IRZB,
    title: 'Rozpocznij generowanie IRZB',
  },
];

const List = () => {
  const pagination = usePagination(10);
  const navigate = useNavigate();
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([{ id: 'createdAt', desc: true }]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);
  const location = useLocation();

  const { data, isError, isFetching, isLoading, isSuccess, error, refetch } = useQuery<
    Page<SettlementData>,
    AxiosError
  >(
    [SETTLEMENT.SETTLEMENT, currentPage, perPage, sortBy],
    () => getSettlements(currentPage, perPage, false, decodeUriSortParams(sortBy)),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (!isFetching) {
      refetch();
    }
  }, [location]);

  return (
    <>
      <Box className="space-y-6">
        {buttons.map(({ description, settlementType, title }) => (
          <div className="flex flex-col lg:!flex-row items-center space-x-4" key={settlementType}>
            <Button
              className="flex-none lg:basis-72"
              onPress={() => navigate('create', { state: { settlementType, title } })}
              variant="secondary">
              {title}
            </Button>

            <Text>{description}</Text>
          </div>
        ))}

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              onSortBy={handleSortBy}
              sortBy={sortBy}
              columns={Columns}
              data={data.content}
              isLoading={isFetching}
              pagination={pagination}
              totalPages={data.totalPages}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Outlet />
    </>
  );
};

export default List;
