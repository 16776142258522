export type AdvancePayment = {
  advanceGrossValue: number;
  advancePaymentId: number;
  advancePaymentType: string;
  agentId: number;
  installments: Installment[];
  paymentDayOfMonth: number;
  paymentStartDate: string;
  finalPaymentDate: string;
  paymentsCount: number;
  title: string;
  installmentToRepayGrossValue: string;
  status?: string;
};

export type Installment = {
  advancePaymentInstallmentId: number;
  grossValue: number;
  paymentDate: string;
  paymentStatus: string;
};

export type AdvancePaymentPayload = Omit<AdvancePayment, 'advancePaymentId'> & {
  advancePaymentId?: number;
};

export enum TransactionType {
  PAYMENT,
  PAYOUT,
}
