import { SortingRule } from 'react-table';

export const decodeUriSortParams = (
  params: SortingRule<any>[],
  customSortRule?: SortingRule<any>
): string => {
  let sortParams = params
    .map(
      (param, index) => (index > 0 ? 'sort=' : '') + param.id + ',' + (param.desc ? 'desc' : 'asc')
    )
    .join('&');

  if (customSortRule) {
    sortParams += '&sort=' + customSortRule.id + ',' + (customSortRule.desc ? 'desc' : 'asc');
  }

  return sortParams;
};
