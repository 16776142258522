import { Button } from '@profitowi/component-library';
import { parse } from 'date-fns';
import { Cell, Column } from 'react-table';

import { COMMISSION_IMPORT_STATUS } from 'constants/dictionaryValues';
import { CommissionDataSource, CommissionSchemas } from 'types/commission';

import { DICTIONARY_TYPES } from '../../../../constants/dictionaryTypes';
import useDictionaryContext from '../../../../hooks/useDictionaryContext';

export const createColumn = (
  deleteCommission: (id: number[]) => void,
  commissions: CommissionSchemas[]
): Column<CommissionDataSource>[] => {
  return [
    {
      Header: 'Data importu',
      accessor: 'importDate',
      sortType: (a, b) =>
        parse(a.values.importDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf() -
        parse(b.values.importDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf(),
    },
    {
      Header: 'Typ',
      accessor: 'importType',
    },
    {
      Header: 'Nazwa pliku',
      accessor: 'originalFileName',
    },
    {
      id: 'commissionSchema',
      accessor: 'commissionSchemaId',
      Header: 'Schemat prowizyjny',
      Cell: ({
        row: {
          original: { commissionSchemaId },
        },
      }: Cell<CommissionDataSource>) => {
        return (
          commissions?.find((commission) => commission.commissionSchemaId === commissionSchemaId)
            ?.schemaName || commissionSchemaId
        );
      },
    },
    {
      Header: 'Towarzystwo ubezpieczeniowe',
      id: 'insuranceCompany.dictionaryValue',
      accessor: 'insuranceCompany',
    },
    {
      Header: 'Komentarz',
      accessor: 'comment',
    },
    {
      Header: 'Okres',
      accessor: 'period',
    },
    {
      Header: 'Status',
      accessor: 'importStatus',
      Cell: ({
        row: {
          original: { importStatus },
        },
      }: Cell<CommissionDataSource>) => {
        const { translate } = useDictionaryContext();

        return importStatus
          ? translate(DICTIONARY_TYPES.COMMISSION_IMPORT_STATUS, importStatus)
          : '';
      },
    },

    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<CommissionDataSource>) => (
        <>
          {original.importStatus !== COMMISSION_IMPORT_STATUS.CANCELED &&
            original.importStatus === COMMISSION_IMPORT_STATUS.ACCEPTED &&
            !original.settlement && (
              <div className="flex space-x-6 justify-end">
                <Button variant="light" onPress={() => deleteCommission([original.id])}>
                  <i className="bi bi-trash cursor-pointer" />
                </Button>
              </div>
            )}
        </>
      ),
    },
  ];
};
