import { Button, Modal, Text } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';

const SettlementConfirmationModal = () => {
  const navigate = useNavigate();

  return (
    <Modal
      title={'Zestawienie zostało wygenerowane!'}
      visible
      className="text-center gap-0"
      size="3xl">
      <Text className="!mt-3 !mb-5">Zestawienie jest dostępne do pobrania w zakładce "Pliki"</Text>

      <Button
        className="flex mx-auto space-x-2 !my-0"
        onPress={() => navigate('..')}
        type="submit"
        size="sm"
        variant="primary">
        <span>Zamknij</span>
      </Button>
    </Modal>
  );
};
export default SettlementConfirmationModal;
