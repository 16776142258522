import {
  CommissionByGroup,
  CommissionByPeriod,
  CommissionDataSource,
  CommissionImportRecords,
  CommissionSchema,
  CommissionSchemaList,
  CommissionSchemaPayload,
  CommissionStructureType,
  Program,
  SchemaCopyPayload,
} from 'types/commission';
import { Page } from 'types/request';
import { commissionmodApi } from 'utils/axios';

export type ImportValidation = {
  ids: number[];
  validationErrors: any;
};

export const getCommissionSchemas = async (page: number, size: number, sort?: string) => {
  const { data } = await commissionmodApi.get<CommissionSchemaList>(`/commission/schemas`, {
    params: { page, size, sort },
  });

  return data;
};

export const getCommissionSchema = async (id?: number, sort?: string, current: boolean = true) => {
  const { data } = await commissionmodApi.get<CommissionSchema>(`/commission/schemas/${id}`, {
    params: { sort, current },
  });

  return data;
};

export const createCommissionSchema = (payload: CommissionSchemaPayload) => {
  return commissionmodApi.post('/commission/schemas', payload);
};

export const updateCommissionSchema = (payload: CommissionSchemaPayload, id: number) => {
  return commissionmodApi.put(`/commission/schemas/${id}`, payload);
};

export const deleteCommissionSchema = (id: number) => {
  return commissionmodApi.delete(`/commission/schemas/${id}`);
};

export const copyCommissionSchema = (payload: SchemaCopyPayload, id: number) => {
  return commissionmodApi.post(`/commission/schemas/${id}/copy`, payload);
};

export const createCommissionSchemaProgram = (payload: Program, id: number) => {
  return commissionmodApi.post(`/commission/schemas/${id}/program`, payload);
};

export const updateCommissionSchemaProgram = (payload: Program, id: number) => {
  return commissionmodApi.put(`/commission/schemas/${id}/program`, payload);
};

export const deleteCommissionSchemaProgram = (
  id: number,
  programId: number,
  endDateTime: string
) => {
  return commissionmodApi.put(`/commission/schemas/${id}/program/${programId}/end`, null, {
    params: { endDateTime },
  });
};

export const deleteCommissionRate = (schemaId: number, agentId: number, rateId: number) =>
  commissionmodApi.delete(`/commission/schemas/${schemaId}/agents/${agentId}/rates/${rateId}`);

export type EditCommissionRatePayload = {
  commissionRate: number;
  validFrom: string;
  validTo?: string | null;
  vatRate: number | null;
};

export const editCommissionRate = (
  schemaId: number,
  agentId: number,
  rateId: number,
  payload: EditCommissionRatePayload
) =>
  commissionmodApi.put(
    `/commission/schemas/${schemaId}/agents/${agentId}/rates/${rateId}`,
    payload
  );

export type AssignCommissionRatePayload = {
  commissionRate: number;
  validFrom: string;
  validTo?: string | null;
  employeePosition: string;
  vatRate: number | null;
};

export const assignCommissionRate = (
  schemaId: number,
  agentId: number,
  payload: AssignCommissionRatePayload
) => commissionmodApi.post(`/commission/schemas/${schemaId}/agents/${agentId}/rates`, payload);

export const getSchemaStructureTypes = async () => {
  const { data } = await commissionmodApi.get<CommissionStructureType>(
    '/commission/schemas/commission-structure-types'
  );

  return data;
};

export const getCommissionImports = async (page: number, size: number, sort?: string) => {
  const params = { page, size };

  const { data } = await commissionmodApi.get<Page<CommissionDataSource>>('/commission/import', {
    params: sort ? { ...params, sort } : params,
  });

  return data;
};

export const getCommissionImport = async (
  page: number,
  size: number,
  commissionImportIdList?: number[],
  sort?: string
) => {
  const params = { page, size };

  const { data } = await commissionmodApi.post<Page<CommissionImportRecords>>(
    `/commission/import/items`,
    commissionImportIdList ? [...commissionImportIdList] : [],
    {
      params,
    }
  );

  return data;
};

export const importCommission = (
  file: any,
  commissionSchemaId?: number,
  insuranceCompany?: string
) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { commissionSchemaId, insuranceCompany },
  };

  return commissionmodApi.post<ImportValidation>('/commission/import', file, config);
};

export const deleteCommissionImport = (commissionImportId?: number[]) => {
  return commissionmodApi.delete(`/commission/import`, { data: commissionImportId });
};

export const acceptCommissionImport = (commissionImportId?: number[]) => {
  return commissionmodApi.patch(`/commission/import/accept`, commissionImportId);
};

export const getCommissionAggregatedByGroup = async (period: string) => {
  const { data } = await commissionmodApi.get<CommissionByGroup>('/commission/by-group', {
    params: { period },
  });

  return data;
};

export const getCommissionForPeriod = async (period: string) => {
  const { data } = await commissionmodApi.get<CommissionByPeriod>('/commission/period', {
    params: { period },
  });

  return data;
};

export const getAgentRates = async (schemaId: number, agentId: number, validAt?: string) => {
  const { data } = await commissionmodApi.get(`/commission/schemas/${schemaId}/agents/${agentId}`, {
    params: { validAt },
  });

  return data;
};
