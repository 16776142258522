import {
  Alert,
  Button,
  CheckboxField,
  DatePickerField,
  InputField,
  Modal,
  SelectField,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { FieldArray, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import { ADDRESS_TYPE } from 'constants/addressType';
import { COOPERATION_FORMS } from 'constants/cooperationForms';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { addAgent } from 'services/agent';
import { AddAgentPayload, Addresses } from 'types/agent';
import { cleanObject } from 'utils/object';

import { FormValues, isCompanyOrSoleProprietorShip, validationSchema } from './validation';

const createEmptyInsuranceCompany = () => ({
  insuranceCompanyDictionaryKey: '',
  validFrom: '' as unknown as Date,
  validTo: '' as unknown as Date,
});

const createEmptyActivityRange = () => ({
  activityRangeDictionaryKey: '',
  validFrom: '' as unknown as Date,
  validTo: '' as unknown as Date,
});

const initialValues: Partial<FormValues> = {
  agentCode: '',
  rauNumber: '',
  cooperationStatus: 'ACTIVE',
  officeDictionaryKey: '',
  economyActivities: [
    {
      type: COOPERATION_FORMS.COMPANY,
      validFrom: '' as unknown as Date,
      companyName: '',
      nip: '',
      regon: '',
      krs: '',
      name: '',
      middleName: '',
      lastName: '',
      pesel: '',
      birthDate: '' as unknown as Date,
      birthPlace: '',
      addresses: [
        {
          type: ADDRESS_TYPE.HOME_ADDRESS,
          apartmentNumber: '',
          postalCode: '',
          city: '',
          houseNumber: '',
          street: '',
          sameAsLivingAddress: false,
        },
        {
          type: ADDRESS_TYPE.BILLING_ADDRESS,
          apartmentNumber: '',
          postalCode: '',
          city: '',
          houseNumber: '',
          street: '',
          sameAsLivingAddress: false,
        },
        {
          type: ADDRESS_TYPE.CORRESPONDENCE_ADDRESS,
          apartmentNumber: '',
          postalCode: '',
          city: '',
          houseNumber: '',
          street: '',
          sameAsLivingAddress: false,
        },
      ],
      economyActivityParams: [
        {
          type: 'VAT_RATE',
          value: 'VAT_ZW',
        },
        {
          type: 'ECONOMY_ACTIVITY_STATE',
          value: 'ACTIVE',
        },
      ],
      bankAccount: '',
      education: '',
      businessEmail: '',
      privateEmail: '',
      phoneNumber: '',
      primaryBillingEmailAddress: '',
      secondBillingEmailAddress: '',
      sendInvoiceByEmail: false,
    },
  ],
  referees: [
    {
      referee: {
        agentCode: '',
      },
      validFrom: '' as unknown as Date,
      validTo: '' as unknown as Date,
    },
  ],
  insuranceCompanies: [],
  activityRanges: [],
};

const AddAgentModal = () => {
  const navigate = useNavigate();
  const { activeDictionaries } = useDictionaryContext();

  const { isLoading, isError, isSuccess, error, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    AddAgentPayload
  >((payload) => addAgent(payload));

  useEffect(() => {
    if (isSuccess) {
      navigate('..');
    }
  }, [isSuccess, navigate]);

  const handleSubmit = (formValues: FormValues) => {
    const formatAddresses = (
      addresses: Array<Partial<Addresses> & { sameAsLivingAddress: boolean | undefined }>
    ) => {
      return addresses
        .map((address, index, arr) => {
          if (index >= 1 && address.sameAsLivingAddress) {
            return {
              street: arr[0].street,
              postalCode: arr[0].postalCode,
              houseNumber: arr[0].houseNumber,
              apartmentNumber: arr[0].apartmentNumber,
              city: arr[0].city,
              type: address.type,
            };
          }

          return {
            street: address.street,
            postalCode: address.postalCode,
            houseNumber: address.houseNumber,
            apartmentNumber: address.apartmentNumber,
            city: address.city,
            type: address.type,
          };
        })
        .filter((address) => Object.keys(cleanObject({ ...address })).length >= 5);
    };

    const submitValues: AddAgentPayload = {
      ...formValues,
      economyActivities: [
        {
          ...formValues.economyActivities?.[0],
          validFrom:
            formValues.economyActivities?.[0]?.validFrom instanceof Date
              ? format(formValues.economyActivities?.[0]?.validFrom, 'dd-MM-yyyy HH:mm:ss')
              : '',
          birthDate:
            formValues.economyActivities?.[0]?.birthDate instanceof Date
              ? format(formValues.economyActivities?.[0]?.birthDate, 'dd-MM-yyyy HH:mm:ss')
              : '',
          addresses: formatAddresses(formValues?.economyActivities?.[0]?.addresses || []),
        },
      ],

      cooperationStatus: 'ACTIVE',
      economyActivityValue: 'ACTIVE',
      activityRanges: (Array.isArray(formValues?.activityRanges)
        ? formValues?.activityRanges
        : []
      ).map((range) => {
        const rangeActivity = { ...range };

        if (!!range.validFrom) {
          rangeActivity.validFrom = format(
            range.validFrom,
            'dd-MM-yyyy HH:mm:ss'
          ) as unknown as Date;
        }

        if (!!range.validTo) {
          rangeActivity.validTo = format(range.validTo, 'dd-MM-yyyy HH:mm:ss') as unknown as Date;
        }

        return rangeActivity;
      }),
      insuranceCompanies: (Array.isArray(formValues.insuranceCompanies)
        ? formValues.insuranceCompanies
        : []
      ).map((company) => {
        const insuranceCompany = { ...company };

        if (!!company.validFrom) {
          insuranceCompany.validFrom = format(
            company.validFrom,
            'dd-MM-yyyy HH:mm:ss'
          ) as unknown as Date;
        }

        if (!!company.validTo) {
          insuranceCompany.validTo = format(
            company.validTo,
            'dd-MM-yyyy HH:mm:ss'
          ) as unknown as Date;
        }

        return insuranceCompany;
      }),
      referees: (Array.isArray(formValues.referees) ? formValues.referees : []).map((referee) => {
        const refereeAgent = { ...referee };

        if (!!referee.validFrom) {
          refereeAgent.validFrom = format(
            referee.validFrom,
            'dd-MM-yyyy HH:mm:ss'
          ) as unknown as Date;
        }

        if (!!referee.validTo) {
          refereeAgent.validTo = format(referee.validTo, 'dd-MM-yyyy HH:mm:ss') as unknown as Date;
        }

        return refereeAgent;
      }),
    } as unknown as AddAgentPayload;

    mutate(cleanObject({ ...submitValues }));
  };

  return (
    <Modal title="Dodaj kontrahenta" visible>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues as FormValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}>
        {({ values }) => {
          return (
            <Form className="space-y-2">
              <InputField label="Numer PR" name="agentCode" type="text" />

              <InputField label="Numer RAU" name="rauNumber" type="text" />

              <SelectField
                options={activeDictionaries?.[DICTIONARY_TYPES.ECONOMY_ACTIVITY_TYPE] || []}
                name="economyActivities[0].type"
                label="Typ"
              />

              <SelectField
                options={activeDictionaries?.[DICTIONARY_TYPES.OFFICE] || []}
                name="officeDictionaryKey"
                placeholder="Biuro"
              />

              <DatePickerField
                name="economyActivities[0].validFrom"
                placeholder="Data rozpoczęcia współpracy"
              />
              <InputField label="Nazwa firmy" name="economyActivities[0].companyName" type="text" />
              <InputField label="NIP" name="economyActivities[0].nip" type="text" />
              <InputField label="REGON" name="economyActivities[0].regon" type="text" />
              <InputField label="KRS" name="economyActivities[0].krs" type="text" />
              <InputField label="Imię" name="economyActivities[0].name" type="text" />
              <div hidden={isCompanyOrSoleProprietorShip(values?.economyActivities?.[0]?.type)}>
                <InputField
                  label="Drugie imię"
                  name="economyActivities[0].middleName"
                  type="text"
                  isDisabled={isCompanyOrSoleProprietorShip(values?.economyActivities?.[0]?.type)}
                />
              </div>
              <InputField label="Nazwisko" name="economyActivities[0].lastName" type="text" />
              {['Adres zamieszkania', 'Adres działalności', 'Adres korespondencyjny'].map(
                (addressTitle, index) => (
                  <div className="address my-8" key={index}>
                    <h2 className="my-8">{addressTitle}</h2>
                    {index >= 1 && (
                      <CheckboxField
                        name={`economyActivities[0].addresses[${index}].sameAsLivingAddress`}>
                        Taki sam jak adres zamieszkania
                      </CheckboxField>
                    )}
                    {!values?.economyActivities?.[0]?.addresses?.[index]?.sameAsLivingAddress && (
                      <>
                        <div className="flex my-2 gap-2">
                          <div className="w-1/3">
                            <InputField
                              label="Ulica"
                              name={`economyActivities[0].addresses[${index}].street`}
                              type="text"
                            />
                          </div>
                          <div className="w-1/3">
                            <InputField
                              label="Numer budynku"
                              name={`economyActivities[0].addresses[${index}].houseNumber`}
                              type="text"
                            />
                          </div>
                          <div className="w-1/3">
                            <InputField
                              label="Numer lokalu"
                              name={`economyActivities[0].addresses[${index}].apartmentNumber`}
                              type="text"
                            />
                          </div>
                        </div>
                        <div
                          className="flex my-2 gap-2"
                          hidden={
                            !!values?.economyActivities?.[0]?.addresses?.[index]
                              ?.sameAsLivingAddress
                          }>
                          <div className="w-1/3">
                            <InputField
                              label="Kod pocztowy"
                              name={`economyActivities[0].addresses[${index}].postalCode`}
                              type="text"
                            />
                          </div>
                          <div className="w-2/3">
                            <InputField
                              label="Miasto"
                              name={`economyActivities[0].addresses[${index}].city`}
                              type="text"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )
              )}

              <InputField label="PESEL" name="economyActivities[0].pesel" type="text" />

              <div hidden={isCompanyOrSoleProprietorShip(values?.economyActivities?.[0]?.type)}>
                <DatePickerField
                  name="economyActivities[0].birthDate"
                  label="Data urodzenia"
                  placeholder="Data urodzenia"
                  disabled={isCompanyOrSoleProprietorShip(values?.economyActivities?.[0]?.type)}
                />
              </div>
              <div hidden={isCompanyOrSoleProprietorShip(values?.economyActivities?.[0]?.type)}>
                <InputField
                  label="Miejsce urodzenia"
                  name="economyActivities[0].birthPlace"
                  type="text"
                  isDisabled={isCompanyOrSoleProprietorShip(values?.economyActivities?.[0]?.type)}
                />
              </div>
              <div hidden={isCompanyOrSoleProprietorShip(values?.economyActivities?.[0]?.type)}>
                <SelectField
                  options={activeDictionaries?.[DICTIONARY_TYPES.EDUCATION] || []}
                  name="economyActivities[0].education"
                  label="Wykształcenie"
                  placeholder="Wykształcenie"
                />
              </div>
              <InputField
                label="Konto bankowe"
                name="economyActivities[0].bankAccount"
                type="text"
              />
              <InputField
                label="Służbowy e-mail"
                name="economyActivities[0].businessEmail"
                type="text"
              />
              <InputField
                label="Prywatny e-mail"
                name="economyActivities[0].privateEmail"
                type="text"
              />
              <InputField
                label="Numer telefonu"
                name="economyActivities[0].phoneNumber"
                type="text"
              />
              <CheckboxField name="economyActivities[0].sendInvoiceByEmail">
                <span className="text-sm">Zgoda na wysyłkę dokumentów e-mailem</span>
              </CheckboxField>
              <InputField
                label="E-mail do wysyłki rozliczeń"
                name="economyActivities[0].primaryBillingEmailAddress"
                type="text"
              />
              <InputField
                label="Drugi e-mail do wysyłki rozliczeń"
                name="economyActivities[0].secondBillingEmailAddress"
                type="text"
              />
              <SelectField
                label="Stawka VAT"
                name="economyActivities[0].economyActivityParams[0].value"
                options={activeDictionaries?.[DICTIONARY_TYPES.VAT_RATE] || []}
                placeholder="Stawka VAT"
              />
              <InputField
                label="Numer PR agenta polecającego"
                name="referees[0].referee.agentCode"
                type="text"
              />
              <div className="flex gap-4">
                <div className="w-1/2">
                  <DatePickerField
                    name="referees[0].validFrom"
                    label="Początek polecenia"
                    placeholder="Początek polecenia"
                    maxDate={values?.referees && values?.referees[0]?.validTo}
                  />
                </div>
                <div className="w-1/2">
                  <DatePickerField
                    name="referees[0].validTo"
                    label="Koniec polecenia"
                    placeholder="Koniec polecenia"
                    minDate={values?.referees && values?.referees[0]?.validFrom}
                  />
                </div>
              </div>
              <div className="my-2 space-y-4">
                <h2 className="my-4">Lista towarzystw ubezpieczeniowych</h2>
                <FieldArray name="insuranceCompanies">
                  {(helpers) => {
                    return (
                      <>
                        <Button
                          onPress={() => helpers.push(createEmptyInsuranceCompany())}
                          className="w-full">
                          Dodaj towarzystwo ubezpieczeniowe
                        </Button>
                        {values?.insuranceCompanies?.map((company, index) => {
                          return (
                            <>
                              <div
                                className="flex flex-col space-y-4"
                                key={company.insuranceCompanyDictionaryKey}>
                                <div className="flex gap-4">
                                  <div className="w-2/3">
                                    <SelectField
                                      options={
                                        activeDictionaries?.[DICTIONARY_TYPES.INSURANCE_COMPANY] ||
                                        []
                                      }
                                      name={`insuranceCompanies[${index}].insuranceCompanyDictionaryKey`}
                                      label={'Towarzystwo ubezpieczeniowe #' + (index + 1)}
                                      placeholder="Towarzystwo ubezpieczeniowe"
                                    />
                                  </div>
                                  <div className="w-1/3 flex items-end">
                                    <Button
                                      onPress={() => helpers.remove(index)}
                                      className="w-full h-12 !py-2">
                                      Usuń
                                    </Button>
                                  </div>
                                </div>
                                <div className="flex gap-4">
                                  <div className="w-1/2">
                                    <DatePickerField
                                      label="Data od"
                                      placeholder="Data od"
                                      name={`insuranceCompanies[${index}].validFrom`}
                                      maxDate={
                                        values?.insuranceCompanies
                                          ? values?.insuranceCompanies[index].validTo
                                          : null
                                      }
                                    />
                                  </div>
                                  <div className="w-1/2">
                                    <DatePickerField
                                      label="Data do"
                                      placeholder="Data do"
                                      name={`insuranceCompanies[${index}].validTo`}
                                      className="w-1/2"
                                      minDate={
                                        values?.insuranceCompanies
                                          ? values?.insuranceCompanies[index].validFrom
                                          : null
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    );
                  }}
                </FieldArray>
              </div>

              <div className="my-2 space-y-4">
                <h2 className="my-4">Lista zakresu czynności</h2>
                <FieldArray name="activityRanges">
                  {(helpers) => {
                    return (
                      <>
                        <Button
                          onPress={() => helpers.push(createEmptyActivityRange())}
                          className="w-full">
                          Dodaj Dodaj zakres czynności
                        </Button>
                        {values?.activityRanges?.map((activityRange, index) => {
                          return (
                            <>
                              <div
                                className="flex flex-col space-y-4"
                                key={activityRange.activityRangeDictionaryKey}>
                                <div className="flex gap-4">
                                  <div className="w-2/3">
                                    <SelectField
                                      options={
                                        activeDictionaries?.[DICTIONARY_TYPES.ACTIVITY_RANGE] || []
                                      }
                                      name={`activityRanges[${index}].activityRangeDictionaryKey`}
                                      label={'Zakres czynności #' + (index + 1)}
                                      placeholder="Zakres czynności"
                                    />
                                  </div>
                                  <div className="w-1/3 flex items-end">
                                    <Button
                                      onPress={() => helpers.remove(index)}
                                      className="w-full h-12 !py-2">
                                      Usuń
                                    </Button>
                                  </div>
                                </div>
                                <div className="flex gap-4">
                                  <div className="w-1/2">
                                    <DatePickerField
                                      label="Data od"
                                      placeholder="Data od"
                                      name={`activityRanges[${index}].validFrom`}
                                      maxDate={
                                        values?.activityRanges
                                          ? values?.activityRanges[index].validTo
                                          : null
                                      }
                                    />
                                  </div>
                                  <div className="w-1/2">
                                    <DatePickerField
                                      label="Data do"
                                      placeholder="Data do"
                                      name={`activityRanges[${index}].validTo`}
                                      className="w-1/2"
                                      minDate={
                                        values?.activityRanges
                                          ? values?.activityRanges[index].validFrom
                                          : null
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    );
                  }}
                </FieldArray>
              </div>
              <ModalActions onCancel={() => navigate('..')} isLoading={isLoading} />

              {isError && (
                <Alert type="error" className="my-8">
                  {error?.response?.status === 409
                    ? error?.response.data.errors[0]
                    : error?.message}
                </Alert>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddAgentModal;
