import { Button } from '@profitowi/component-library';
import { parse } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { SETTLEMENT_STATUS, SettlementData, SettlementType } from 'types/settlement';

export const Columns: Column<SettlementData>[] = [
  {
    Header: 'Data rozpoczęcia',
    accessor: 'createdAt',
    sortType: (a, b) =>
      parse(a.values.createdAt, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf() -
      parse(b.values.createdAt, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf(),
  },
  {
    Header: 'Typ rozliczenia',
    accessor: 'type',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('SETTLEMENT_TYPE', value) : '';
    },
  },
  {
    Header: 'Rozliczenie',
    accessor: 'id',
  },
  {
    Header: 'Użytkownik',
    accessor: 'lastModifiedBy',
  },
  {
    Header: 'Ostatnia modyfikacja',
    accessor: 'lastModifiedAt',
    sortType: (a, b) =>
      parse(a.values.lastModifiedAt, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf() -
      parse(b.values.lastModifiedAt, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf(),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('SETTLEMENT_STATUS', value) : '';
    },
  },
  {
    Header: 'Etap',
    accessor: 'stage',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('SETTLEMENT_STAGE', value) : '';
    },
  },
  {
    Header: 'Oznaczenie',
    accessor: 'signature',
  },
  {
    Header: 'Data utworzenia dokumentów księgowych',
    accessor: 'accountingDocumentCreationDate',
    sortType: (a, b) =>
      parse(a.values.accountingDocumentCreationDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf() -
      parse(b.values.accountingDocumentCreationDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf(),
  },
  {
    Header: 'Komentarz',
    accessor: 'comment',
  },
  {
    id: 'operations',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell<SettlementData>) => {
      const navigate = useNavigate();
      const location = useLocation();

      return (
        <div className="flex justify-end">
          {original.status === SETTLEMENT_STATUS.IN_PROGRESS && (
            <Button
              variant="light"
              onPress={() =>
                navigate(
                  `${location.pathname.includes('settlements') ? '..' : './settlements'}/${
                    original.id
                  }`,
                  {
                    state: {
                      isContinued: true,
                    },
                  }
                )
              }>
              Kontynuuj
            </Button>
          )}

          {original.type !== SettlementType.IRZB && original.status === SETTLEMENT_STATUS.FINISHED && (
            <div className="flex space-x-2">
              <Button
                variant="light"
                onPress={() =>
                  navigate(
                    `${location.pathname.includes('settlements') ? '..' : './settlements'}/${
                      original.id
                    }/emails`
                  )
                }>
                Wiadomości
              </Button>
              <Button
                variant="light"
                onPress={() =>
                  navigate(
                    `${location.pathname.includes('settlements') ? '..' : './settlements'}/${
                      original.id
                    }/preview/documents`
                  )
                }>
                Podgląd
              </Button>
            </div>
          )}
        </div>
      );
    },
  },
];
