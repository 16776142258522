import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ConfirmationModal from 'components/ConfimartionModal/ConfirmationModal';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import Searchbox from 'components/Searchbox/Searchbox';
import useAgentRoleContext from 'hooks/useAgentRoleContext';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { useUsersQuery } from 'hooks/useUsersQuery';
import { disableUser, enableUser } from 'services/user';
import {
  AddUserPayload,
  ChangePasswordPayload,
  EditRolesPayload,
  EditUserPayload,
  Roles,
  User,
  UserAction,
  UserType,
} from 'types/user';

import useAddUser from '../common/hooks/useAddUser';
import useChangePassword from '../common/hooks/useChangePassword';
import useEditRoles from '../common/hooks/useEditRoles';
import useEditUser from '../common/hooks/useEditUser';
import AddUserForm from './AddUserForm/AddUserForm';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';
import EditRolesForm from './EditRolesForm/EditRolesForm';
import EditUserForm from './EditUserForm/EditUserForm';
import { createColumn } from './columns';

const Users = () => {
  const [editedItem, setEditedItem] = useState<User>();
  const [searchParam, setSearchParam] = useState<string>();
  const [changeStatusModalVisible, setChangeStatusModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [editUserModalVisible, setEditUserModalVisible] = useState(false);
  const [editRolesModalVisible, setEditRolesModalVisible] = useState(false);
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [userType, setUserType] = useState<UserType>();

  const navigate = useNavigate();

  const pagination = usePagination(10);
  const { currentPage, perPage, setPage } = pagination;

  const { translate } = useDictionaryContext();
  const { isSuperAdminRole } = useAgentRoleContext();
  const { userList, refetch, isFetching, error, isError } = useUsersQuery(
    currentPage,
    perPage,
    searchParam
  );

  const {
    error: changeStatusError,
    isError: isChangeStatusError,
    isLoading: isChangeStatusLoading,
    mutate: mutateChangeStatus,
    reset: resetChangeStatus,
  } = useMutation<AxiosResponse, AxiosError>(
    () =>
      editedItem?.enabled
        ? disableUser(editedItem.keycloakId)
        : enableUser(editedItem?.keycloakId || ''),
    {
      onSuccess: () => {
        if (editedItem) {
          editedItem.enabled = !editedItem.enabled;
        }
        onChangeStatusModalClose();
      },
    }
  );

  const closeModal = () => {
    setEditedItem(undefined);
  };

  const addUser = useAddUser(refetch, () => {
    setAddUserModalVisible(false);
    closeModal();
  });

  const editUser = useEditUser(editedItem?.keycloakId || '', refetch, () => {
    setEditUserModalVisible(false);
    closeModal();
  });

  const editRoles = useEditRoles(editedItem?.keycloakId || '', refetch, () => {
    setEditRolesModalVisible(false);
    closeModal();
  });

  const changePassword = useChangePassword();

  const onAddUserSave = (values: AddUserPayload) => {
    if (userType === UserType.PORTAL_USER) {
      const payload = {
        ...values,
        roles: [Roles.CONTRACTOR],
      };

      addUser.mutate(payload);
    } else {
      const { agentCode, ...rest } = values;

      addUser.mutate(rest);
    }
  };

  const onEditUserSave = (payload: EditUserPayload) => {
    editUser.mutate(payload);
  };

  const onEditRolesSave = (payload: EditRolesPayload) => {
    editRoles.mutate(payload);
  };

  const onPasswordSave = (payload: ChangePasswordPayload) => {
    if (editedItem) {
      changePassword.mutate({
        password: payload.password,
        userId: editedItem.keycloakId,
        temporary: true,
      });
    }
  };

  const handleAction = (user: User, action: UserAction) => {
    setEditedItem(user);
    switch (action) {
      case 'CHANGE_STATUS':
        setChangeStatusModalVisible(true);
        break;
      case 'CHANGE_PASSWORD':
        setPasswordModalVisible(true);
        break;
      case 'CREATE_USER':
        setAddUserModalVisible(true);
        break;
      case 'EDIT_DATA':
        setEditUserModalVisible(true);
        break;
      case 'EDIT_ROLES':
        setEditRolesModalVisible(true);
        break;
      default:
        break;
    }
  };

  const onChangeStatusModalClose = () => {
    setChangeStatusModalVisible(false);
    closeModal();
    resetChangeStatus();
  };

  const onChangePasswordModalClose = () => {
    const { isSuccess, isError, reset } = changePassword;

    setPasswordModalVisible(false);
    closeModal();
    (isError || isSuccess) && reset();
  };

  const onAddUserModalClose = () => {
    setAddUserModalVisible(false);
    setUserType(undefined);
    closeModal();
    addUser.isError && addUser.reset();
  };

  const onEditUserModalClose = () => {
    setEditUserModalVisible(false);
    closeModal();
    editUser.isError && editUser.reset();
  };

  const onEditRolesModalClose = () => {
    setEditRolesModalVisible(false);
    closeModal();
    editRoles.isError && editRoles.reset();
  };

  const addPortalUser = () => {
    setUserType(UserType.PORTAL_USER);
    setAddUserModalVisible(true);
  };

  const addCommissionUser = () => {
    setUserType(UserType.COMMISSION_USER);
    setAddUserModalVisible(true);
  };

  function handleSearch(queryParam: string) {
    setPage(0);
    setSearchParam(queryParam);
  }

  const handleGenerateReport = () => {
    navigate('generateReport');
  };

  return (
    <>
      <Box className="space-y-6">
        <div className="flex flex-col">
          <Header as="h4" size="lg" weight="semibold" className="mb-4">
            Użytkownicy
          </Header>
          <div className="flex justify-between items-center gap-4 flex-wrap">
            <div className="flex gap-4">
              <Searchbox searchCallback={handleSearch} />
              <Button
                variant="outline-primary"
                className="px-4 py-1"
                onPress={() => handleGenerateReport()}>
                <Text weight="semibold">Eksportuj dane</Text>
              </Button>
            </div>
            <div className="flex space-x-4 items-center">
              <Button
                variant="outline-primary"
                className="px-4 py-1"
                onPress={() => addPortalUser()}>
                <Text weight="semibold">
                  Dodaj użytkownika Portalu Agenta
                  <i className="bi bi-plus-lg ml-2"></i>
                </Text>
              </Button>
              {isSuperAdminRole && (
                <Button
                  variant="outline-primary"
                  className="px-4 py-1"
                  onPress={() => addCommissionUser()}>
                  <Text weight="semibold">
                    Dodaj użytkownika Systemu Prowizyjnego
                    <i className="bi bi-plus-lg ml-2"></i>
                  </Text>
                </Button>
              )}
            </div>
          </div>
        </div>
        {isError && <ErrorMessages error={error} />}
        {userList?.content ? (
          <Box.FullWidth>
            <Table
              data={userList.content}
              columns={createColumn(translate, handleAction)}
              isLoading={isFetching}
              pagination={pagination}
              totalPages={userList.totalPages}
            />
          </Box.FullWidth>
        ) : (
          <Loader className="h-12 w-12" />
        )}
      </Box>
      {changeStatusModalVisible && (
        <ConfirmationModal
          confirmMessage="Czy na pewno chcesz zmienić status użytkownika?"
          isError={isChangeStatusError}
          isLoading={isChangeStatusLoading}
          error={changeStatusError}
          mutate={mutateChangeStatus}
          onCancel={() => onChangeStatusModalClose()}
        />
      )}
      {passwordModalVisible && editedItem && (
        <ChangePasswordForm
          visible={passwordModalVisible}
          setVisible={setPasswordModalVisible}
          onSave={onPasswordSave}
          closeModal={onChangePasswordModalClose}
          changePasswordError={changePassword.error}
          editedItem={editedItem}
          isSuccess={changePassword.isSuccess}
        />
      )}
      {addUserModalVisible && (
        <AddUserForm
          visible={addUserModalVisible}
          setVisible={setPasswordModalVisible}
          onSave={onAddUserSave}
          closeModal={onAddUserModalClose}
          userType={userType}
          addUserError={addUser.error}
        />
      )}
      {editUserModalVisible && !!editedItem && (
        <EditUserForm
          visible={editUserModalVisible}
          setVisible={setEditUserModalVisible}
          onSave={onEditUserSave}
          closeModal={onEditUserModalClose}
          editedItem={editedItem}
          editUserError={editUser.error}
        />
      )}
      {editRolesModalVisible && !!editedItem && (
        <EditRolesForm
          visible={editRolesModalVisible}
          setVisible={setEditRolesModalVisible}
          onSave={onEditRolesSave}
          closeModal={onEditRolesModalClose}
          editedItem={editedItem}
          editRoles={editRoles.error}
        />
      )}
    </>
  );
};

export default Users;
