import { Button } from '@profitowi/component-library';
import { NavigateFunction } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { PAYMENT_STATUS_TYPES } from 'constants/paymentStatusTypes';
import { findDictionaryByKey } from 'helpers/dictionaries';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { AdvancePayment } from 'types/advancePayment';
import { DictionaryTypes } from 'types/dictionary';
import { formatBackendDateWithoutTime } from 'utils/date';

export const createColumn = (
  agentId: number,
  navigate: NavigateFunction,
  dictionaries: DictionaryTypes,
  mutate: (advancePaymentId: number) => void,
  openConfirmModal: (advanceId: number) => void
): Column<AdvancePayment>[] => {
  return [
    {
      Header: 'Typ zaliczki',
      id: 'advancePaymentType',
      accessor: (row: AdvancePayment) => {
        if (dictionaries) {
          const value = findDictionaryByKey(
            row.advancePaymentType,
            dictionaries[DICTIONARY_TYPES.ADVANCE_PAYMENT_TYPE]
          );

          if (value) return value.value;
        }
      },
    },
    {
      Header: 'Tytuł zaliczki',
      accessor: 'title',
    },
    {
      Header: 'Kwota brutto',
      accessor: (row) => <div className="text-right">{row?.advanceGrossValue}</div>,
    },
    {
      Header: 'Liczba miesięcy',
      accessor: 'paymentsCount',
    },
    {
      Header: 'Data rozpoczęcia spłat',
      accessor: 'paymentStartDate',
      Cell: ({
        row: {
          original: { paymentStartDate },
        },
      }: Cell<AdvancePayment>) => formatBackendDateWithoutTime(paymentStartDate),
    },
    {
      Header: 'Dzień spłaty w miesiącu',
      accessor: 'paymentDayOfMonth',
    },
    {
      Header: 'Pozostało do spłaty',
      accessor: 'installmentToRepayGrossValue',
    },
    {
      Header: 'Status',
      Cell: ({
        row: {
          original: { status },
        },
      }: Cell<AdvancePayment>) => {
        const { translate } = useDictionaryContext();

        return (
          status && <span>{status ? translate(DICTIONARY_TYPES.PAYMENT_STATUS, status) : ''}</span>
        );
      },
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { advancePaymentId, status },
        },
      }: Cell<AdvancePayment>) => {
        const isPayedOrCanceled =
          status === PAYMENT_STATUS_TYPES.CANCELLED ||
          status === PAYMENT_STATUS_TYPES.CANCELLED_PAYED ||
          status === PAYMENT_STATUS_TYPES.PAYED;

        return (
          <div className="flex space-x-4 justify-end">
            <Button
              variant="light"
              onPress={() => navigate(`/records/agent/${agentId}/advances/${advancePaymentId}`)}>
              <i className="bi bi-gear cursor-pointer" />
            </Button>

            {!isPayedOrCanceled && (
              <div className="flex space-x-4">
                <Button variant="light" onPress={() => mutate(advancePaymentId)}>
                  <i className="bi bi-trash cursor-pointer" />
                </Button>

                {status === PAYMENT_STATUS_TYPES.TO_PAY && (
                  <Button
                    size="sm"
                    variant="light"
                    onPress={() => openConfirmModal(advancePaymentId)}>
                    Generuj wypłatę zaliczki
                  </Button>
                )}
              </div>
            )}
          </div>
        );
      },
    },
  ];
};
