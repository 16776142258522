import {
  Box,
  Button,
  CheckboxField,
  DatePickerField,
  InputField,
  Loader,
  MultiSelectField,
  Option,
  SelectField,
  Table,
  Text,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash-es';
import DelegateViceModal from 'pages/Records/components/SalesStructure/components/DelegateViceModal/DelegateViceModal';
import ReportModal from 'pages/Reports/components/ReportModal/ReportModal';
import { generateReportInitialValue } from 'pages/Reports/components/helper/form';
import useReportConfigQuery from 'pages/Reports/components/hooks/useReportConfigQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';
import * as yup from 'yup';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { STRUCTURE } from 'constants/queries/structure';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useAgentRoleContext from 'hooks/useAgentRoleContext';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { generateReport } from 'services/reports';
import { getSubordinationStructureView } from 'services/structure';
import { ReportType } from 'types/reports';
import { Page } from 'types/request';
import { AgentRelationStructureRecord } from 'types/structure';
import { Roles } from 'types/user';
import { decodeUriSortParams } from 'utils/table';

import { columns } from './columns';
import AddAgentToStructureModal from './components/AddAgentToStructureModal/AddAgentToStructureModal';
import AddCommissionConditionModal from './components/AddCommissionConditionModal';
import AddPositionModal from './components/AddPositionModal';
import ChangeSubordinationPeriodModal from './components/ChangeSubordinationPeriodModal';
import ChangeSuperiorModal from './components/ChangeSuperiorModal/ChangeSuperiorModal';
import DeleteCommissionModal from './components/DeleteCommissionModal';
import DeletePositionModal from './components/DeletePositionModal';
import EditCommissionModal from './components/EditCommissionModal';
import EditPositionModal from './components/EditPositionModal';
import PositionTable from './components/PositionTable';
import PromoteAgentsModal from './components/PromoteAgentsModal';
import RemoveAgentFromSchemaModal from './components/RemoveAgentFromSchemaModal';

const validationSchema = yup.object({
  commissionSchema: yup.number(),
  agentCode: yup.string(),
  term: yup.string(),
  childPositions: yup.array().of(yup.string().required()),
  parentPositions: yup.array().of(yup.string().required()),
  directOnly: yup.boolean(),
  time: yup.mixed().test('dateValidation', 'Date Validation', (date, { createError }) => {
    if (!date) {
      return createError({
        message: requiredFieldMessage,
      });
    }

    return true;
  }),
});

export type SalesStructureFilterValues = yup.InferType<typeof validationSchema>;

const initialValues = {
  commissionSchema: undefined,
  agentCode: '',
  term: '',
  childPositions: [],
  parentPositions: [],
  directOnly: false,
  time: new Date(),
};

type Props = {
  agentCode?: string;
  agentId?: number;
  isAgentView?: boolean;
};

const SalesStructure = ({ agentCode, agentId, isAgentView = false }: Props) => {
  const pagination = usePagination(10);
  const [runCommissionsRefetch, setRunCommissionsRefetch] = useState(false);
  const [payload, setPayload] = useState<SalesStructureFilterValues>({
    ...initialValues,
    agentCode: agentCode ? agentCode : initialValues.agentCode,
  });
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const [isPromoteAgentsModalVisible, setIsPromoteAgentsModalVisible] = useState<boolean>(false);
  const [isChangeSuperiorModalVisible, setIsChangeSuperiorModalVisible] = useState<boolean>(false);
  const [isAddAgentModalVisible, setIsAddAgentModalVisible] = useState<boolean>(false);
  const [areOptionsModalEnabled, setAreOptionsModalEnabled] = useState<boolean>(false);
  const [commissionSchemaId, setCommissionSchemaId] = useState<number | undefined>();
  const [selectedRows, setSelectedRows] = useState<AgentRelationStructureRecord[]>([]);
  const [isCustomRowStyle, setIsCustomRowStyle] = useState(false);
  const { activeDictionaries } = useDictionaryContext();
  const navigate = useNavigate();
  const { hasRole } = useAgentRoleContext();

  const { currentPage, perPage, setPage } = pagination;

  const isAgentCode = useMemo(
    () => !!(payload.agentCode && payload.agentCode?.length > 0),
    [agentCode, payload]
  );

  useEffect(() => {
    if (isAgentView && !isCustomRowStyle) setIsCustomRowStyle(true);
  }, [isAgentView, isCustomRowStyle]);

  useEffect(() => {
    if (agentCode && agentCode?.length > 0 && !payload.agentCode)
      setPayload({ ...payload, agentCode });
  }, [agentCode]);

  const commissionSchemaOptions: Option[] = useMemo(
    () => [
      { key: '', value: 'Dowolny' },
      ...(activeDictionaries?.COMMISSION_SCHEMA_NAME?.map(({ key, value }) => ({ key, value })) ||
        []),
    ],
    [activeDictionaries?.COMMISSION_SCHEMA_NAME]
  );

  const positionOptions = useMemo(
    () => [
      { key: '', value: 'Dowolny' },
      ...(activeDictionaries?.POSITION?.map(({ key, value }) => ({ key, value })) || []),
    ],
    [activeDictionaries?.POSITION]
  );

  const { error, data, isError, isFetching, isLoading, isSuccess, refetch } = useQuery<
    Page<AgentRelationStructureRecord>,
    AxiosError
  >(
    [STRUCTURE.STRUCTURE_SUBORDINATION, payload, currentPage, perPage, sortBy],
    () =>
      getSubordinationStructureView(
        {
          ...payload,
          time: format(payload.time as Date, 'dd-MM-yyyy 00:00:00'),
        },
        currentPage,
        perPage,
        decodeUriSortParams(sortBy)
      ),
    {
      onSuccess: () => {
        setRunCommissionsRefetch(true);
      },
      keepPreviousData: true,
      enabled: isAgentView ? isAgentCode : true,
    }
  );

  const { reportConfig } = useReportConfigQuery({
    reportType: ReportType.SUBORDINATION_STRUCTURE,
  });

  const handleSelectionChange = useCallback((rows: AgentRelationStructureRecord[]) => {
    setSelectedRows(rows);
  }, []);

  const formatAgentCode = (agentCode: string | null | undefined): string => {
    if (!agentCode) {
      return '';
    }

    let formattedAgentCode = `${agentCode}`;

    if (/^[0-9]{4}$/.test(agentCode)) {
      formattedAgentCode = `pr${agentCode}`;
    }

    return formattedAgentCode.toUpperCase();
  };

  const formatTerm = (term: string | null | undefined): string => {
    if (!term) {
      return '';
    }

    return `${term}`.toUpperCase();
  };

  const handleSubmit = (values: SalesStructureFilterValues) => {
    setPage(0);
    setPayload({
      ...values,
      agentCode: agentCode ? agentCode : formatAgentCode(values?.agentCode),
      term: formatTerm(values?.term),
    });
    setAreOptionsModalEnabled(!!values.commissionSchema);
    setCommissionSchemaId(values.commissionSchema);
    values.agentCode && !isCustomRowStyle && setIsCustomRowStyle(true);
  };

  const handleReset = () => {
    setPayload({
      ...initialValues,
      agentCode: agentCode ? agentCode : initialValues.agentCode,
    });
    !agentCode && setIsCustomRowStyle(false);
  };

  const setCustomRowStyleCallback = (values: any) => {
    if (values['child.id'] === payload.agentCode) return 'bg-blue-100';
    else if (values['parent.id'].includes(payload.agentCode)) return 'bg-green-100';

    return '';
  };

  const generateReport = () => {
    const { agentCode, commissionSchema, childPositions, parentPositions, time, directOnly } =
      payload;

    const defaultValues = generateReportInitialValue(
      ReportType.SUBORDINATION_STRUCTURE,
      reportConfig
    );

    const initialValues = {
      ...defaultValues,
      COMMISSION_SCHEMA: commissionSchema ?? '',
      CHILD_CODE: agentCode ?? '',
      TIME: new Date(time),
      CHILD_POSITIONS: childPositions ?? '',
      PARENT_POSITIONS: parentPositions ?? '',
      DIRECT_ONLY: directOnly ?? false,
    };

    navigate('generateReport', { state: { defaultInitialValues: initialValues } });
  };

  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);
  const isAdminOrReportRole =
    hasRole(Roles.ADMIN) || hasRole(Roles.SUPER_ADMIN) || hasRole(Roles.REPORTS);

  return (
    <>
      <Box className="space-y-6 w-full ">
        <div className="flex justify-between">
          <div className="w-1/3">
            <Formik
              initialValues={agentCode ? { ...initialValues, agentCode } : initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize>
              {({ values }) => (
                <Form className="space-y-4 max-w-full w-full">
                  {!agentCode && (
                    <div className="flex space-x-4">
                      <InputField label="Kod kontrahenta" name="agentCode" />
                      <InputField label="Wyszukaj" name="term" />
                    </div>
                  )}

                  <MultiSelectField
                    label="Stanowisko kontrahenta podrzędnego"
                    name="childPositions"
                    options={positionOptions}
                  />

                  <MultiSelectField
                    label="Stanowisko kontrahenta nadrzędnego"
                    name="parentPositions"
                    options={positionOptions}
                  />
                  <SelectField
                    className="w-full"
                    label="Schemat"
                    name="commissionSchema"
                    options={commissionSchemaOptions}
                  />
                  <DatePickerField label="Struktura na dzień" name="time" />

                  <CheckboxField name="directOnly">Pokaż tylko relacje bezpośrednie</CheckboxField>

                  <div className="flex space-x-4">
                    <Button
                      isDisabled={isFetching || isLoading || isEqual(values, payload)}
                      size="sm"
                      variant="primary"
                      type="submit">
                      Szukaj
                    </Button>

                    <Button size="sm" variant="primary" type="reset" onPress={handleReset}>
                      Wyczyść filtry
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="flex flex-col justify-between w-1/3">
            <div className="flex flex-col gap-4 items-end">
              <Button
                size="sm"
                variant="outline-primary"
                onPress={() => setIsAddAgentModalVisible(true)}
                className="w-1/2">
                Dodaj kontrahenta
              </Button>

              <Button
                size="sm"
                variant="outline-primary"
                onPress={() => setIsPromoteAgentsModalVisible(true)}
                className="w-1/2"
                isDisabled={!payload.commissionSchema || !selectedRows.length}>
                Zmień stanowisko
              </Button>

              <Button
                size="sm"
                variant="outline-primary"
                onPress={() => setIsChangeSuperiorModalVisible(true)}
                className="w-1/2"
                isDisabled={!areOptionsModalEnabled || !selectedRows.length}>
                Zmień nadrzędnego
              </Button>

              {isAdminOrReportRole && (
                <Button
                  size="sm"
                  variant="outline-primary"
                  onPress={() => generateReport()}
                  className="w-1/2">
                  Wygeneruj Raport
                </Button>
              )}
            </div>

            {isCustomRowStyle && (
              <div className="border-2 space-y-2 p-4">
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-blue-200"></div>
                  <Text>Wyszukany kontrahent jest podrzędnym</Text>
                </div>

                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-green-200"></div>
                  <Text>Wyszukany kontrahent jest nadrzędnym</Text>
                </div>
              </div>
            )}
          </div>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              columns={columns}
              data={data?.content}
              isExpandable
              isLoading={isFetching}
              isSelectable
              onSelectionChange={handleSelectionChange}
              pagination={pagination}
              onSortBy={handleSortBy}
              sortBy={sortBy}
              isCustomRowStyle={isCustomRowStyle}
              customRowStyleCallback={setCustomRowStyleCallback}
              renderRowSubComponent={({ row }) => (
                <div className="pl-20">
                  <PositionTable
                    runCommissionsRefetch={runCommissionsRefetch}
                    setRunCommissionsRefetch={setRunCommissionsRefetch}
                    row={row}
                    filterDate={payload.time as Date}
                  />
                </div>
              )}
              totalPages={data?.totalPages}
            />
          </Box.FullWidth>
        )}
      </Box>

      <AddAgentToStructureModal
        visible={isAddAgentModalVisible}
        closeModal={() => setIsAddAgentModalVisible(false)}
        refetch={refetch}
      />

      <ChangeSuperiorModal
        isVisible={isChangeSuperiorModalVisible}
        closeModal={() => setIsChangeSuperiorModalVisible(false)}
        commissionSchemaId={commissionSchemaId}
        selectedRows={selectedRows}
      />

      {isPromoteAgentsModalVisible && (
        <PromoteAgentsModal
          isVisible={isPromoteAgentsModalVisible}
          closeModal={() => setIsPromoteAgentsModalVisible(false)}
          commissionSchemaId={
            typeof commissionSchemaId === 'number'
              ? commissionSchemaId.toString()
              : commissionSchemaId
          }
          selectedRows={selectedRows}
        />
      )}

      <Routes>
        <Route
          path="changeSubordinationPeriod"
          element={<ChangeSubordinationPeriodModal refetch={() => refetch()} />}
        />
        <Route path="deletePosition" element={<DeletePositionModal refetch={() => refetch()} />} />
        <Route
          path="deleteCommissionRate"
          element={<DeleteCommissionModal refetch={() => setRunCommissionsRefetch(true)} />}
        />
        <Route
          path="addCommissionCondition"
          element={<AddCommissionConditionModal refetch={() => refetch()} />}
        />
        <Route
          path="editCommissionRate"
          element={<EditCommissionModal refetch={() => setRunCommissionsRefetch(true)} />}
        />
        <Route
          path="removeAgentFromSchema"
          element={<RemoveAgentFromSchemaModal refetch={() => refetch()} />}
        />
        <Route path="addPosition" element={<AddPositionModal refetch={() => refetch()} />} />
        <Route path="editPosition" element={<EditPositionModal refetch={() => refetch()} />} />
        <Route path="delegateVice" element={<DelegateViceModal />} />
        <Route
          path="generateReport/*"
          element={<ReportModal initialReportType={ReportType.SUBORDINATION_STRUCTURE} />}
        />
      </Routes>
    </>
  );
};

export default SalesStructure;
