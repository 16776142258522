import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { Comment } from 'types/comment';

export const columns: Column<Comment>[] = [
  { Header: 'Data dodania komentarza', accessor: 'createdAt' },
  { Header: 'Login użytkownika', accessor: 'createdBy' },
  { Header: 'Treść komentarza', accessor: 'comment' },
  {
    id: 'operations',
    Cell: ({
      row: {
        original: { id },
      },
    }: Cell<Comment>) => {
      const navigate = useNavigate();

      return (
        <div className="flex justify-end">
          <Button variant="light" onPress={() => navigate('deleteComment', { state: { id } })}>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      );
    },
  },
];
