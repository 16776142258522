import { CheckboxField, Option, SelectField, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMISSION } from 'constants/queries/commission';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { ImportValidation, getCommissionSchemas, importCommission } from 'services/commission';
import { CommissionImportPayload, CommissionSchemaList } from 'types/commission';

import { DICTIONARY_TYPES } from '../../../../../../constants/dictionaryTypes';

const validationSchema = yup.object({
  insuranceCompany: yup.string().required(requiredFieldMessage),
  file: yup.mixed().required(requiredFieldMessage),
  areSchemasMarkedInFile: yup.boolean().default(true).required(requiredFieldMessage),
  productGroup: yup.string().default(undefined),
  commissionSchemaId: yup
    .string()
    .when('areSchemasMarkedInFile', {
      is: (areSchemasMarkedInFile: boolean) => areSchemasMarkedInFile === false,
      then: yup.string().required(requiredFieldMessage),
    })
    .default(''),
});

type FormValues = yup.InferType<typeof validationSchema>;

const ComissionSchemaSelect = ({
  commissions,
  isDisabled,
}: {
  commissions?: CommissionSchemaList;
  isDisabled: boolean;
}) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [commissionOptions, setCommissionOptions] = useState<Option[]>([]);

  useEffect(() => {
    let tempOptions: Option[] | undefined = [];
    if (!values.productGroup) {
      tempOptions = commissions?.content.map((item) => ({
        value: item.schemaName,
        key: item.commissionSchemaId.toString(),
      }));
    } else {
      tempOptions = commissions?.content
        ?.filter((option) => option.productGroup === values.productGroup)
        .map((item) => ({
          value: item.schemaName,
          key: item.commissionSchemaId.toString(),
        }));
    }
    if (tempOptions) {
      setFieldValue('commissionSchemaId', undefined);
      setCommissionOptions(tempOptions);
    }
  }, [commissions?.content, values.productGroup]);

  return (
    <SelectField
      label="Schemat prowizyjny"
      name="commissionSchemaId"
      options={isDisabled ? commissionOptions : []}
    />
  );
};

type Props = {
  visible?: boolean;
  setCommissionSchemaId: (value: number[]) => void;
  setStep: (step: number) => void;
  onSave?: (payload: CommissionImportPayload) => void;
  setVisible?: (value: boolean) => void;
};

const CommissionImportForm = ({ setCommissionSchemaId, setStep }: Props) => {
  const navigate = useNavigate();
  const { activeDictionaries } = useDictionaryContext();

  const {
    data: commissions,
    error: commissionsError,
    isError: commissionsIsError,
  } = useQuery<CommissionSchemaList, AxiosError>([COMMISSION.COMMISSION_SCHEMA], () =>
    getCommissionSchemas(0, 1000)
  );

  const createCommissionImports = useMutation<
    AxiosResponse<ImportValidation>,
    AxiosError,
    CommissionImportPayload
  >(
    ({ file, commissionSchemaId, insuranceCompany }) =>
      importCommission(file, commissionSchemaId, insuranceCompany),
    {
      onSuccess: ({ data }) => {
        setCommissionSchemaId(data.ids);
        setStep(2);
      },
    }
  );

  const insuranceCompanies = useMemo(
    () => activeDictionaries?.INSURANCE_COMPANY || [],
    [activeDictionaries?.INSURANCE_COMPANY]
  );

  const onChange = (form: any, e: any) => {
    form.setFieldValue('file', e.target.files[0]);
  };

  const onSubmit = (values: FormValues) => {
    const formData = new FormData();
    formData.append('file', values.file);

    const payload = {
      ...values,
      file: formData,
      areSchemasMarkedInFile: values.areSchemasMarkedInFile,
      commissionSchemaId: values.areSchemasMarkedInFile
        ? undefined
        : parseInt(values.commissionSchemaId),
      productGroup: values.areSchemasMarkedInFile ? undefined : values.productGroup,
    };

    createCommissionImports.mutate(payload);
  };

  return (
    <Formik
      validateOnMount
      initialValues={{
        commissionSchemaId: undefined as unknown as string,
        file: null,
        areSchemasMarkedInFile: true,
        productGroup: undefined as unknown as string,
        insuranceCompany: undefined as unknown as string,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ errors, touched, values }) => {
        return (
          <Form className="space-y-4">
            <SelectField
              label="Towarzystwo ubezpieczeniowe"
              name="insuranceCompany"
              options={insuranceCompanies}
            />

            <div>
              <Field name="file">
                {({ form }: any) => (
                  <input type="file" name="file" onChange={(e) => onChange(form, e)} />
                )}
              </Field>

              {errors.file && touched.file ? (
                <Text size="sm" className="text-secondary-red">
                  {errors.file}
                </Text>
              ) : null}
            </div>

            <CheckboxField name="areSchemasMarkedInFile">
              Oznaczenie schematów w pliku
            </CheckboxField>

            <SelectField
              options={
                !values.areSchemasMarkedInFile && activeDictionaries
                  ? activeDictionaries[DICTIONARY_TYPES.PRODUCT_GROUP]
                  : []
              }
              name="productGroup"
              label="Grupa produktowa"
            />

            <ComissionSchemaSelect
              isDisabled={!values.areSchemasMarkedInFile}
              commissions={commissions}
            />

            <ModalActions
              isLoading={createCommissionImports.isLoading}
              confirmLabel="Załaduj Plik"
              disableSubmit={!!Object.values(errors).length}
              onCancel={() => navigate('..')}
            />

            {commissionsIsError && <ErrorMessages error={commissionsError} />}
            {createCommissionImports.isError && (
              <ErrorMessages error={createCommissionImports.error} />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CommissionImportForm;
