import { Box, Button, Loader, Table } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMISSION } from 'constants/queries/commission';
import { usePagination } from 'hooks/usePagination';
import {
  acceptCommissionImport,
  deleteCommissionImport,
  getCommissionImport,
} from 'services/commission';
import { CommissionImportRecords } from 'types/commission';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import { columns } from './columns';

type Props = {
  commissionImportId?: number[];
  refetchImports: () => void;
  setStep: (step: number) => void;
  setImportRecordsLength: (values?: number) => void;
};

const CommissionImportPreview = ({
  commissionImportId,
  refetchImports,
  setStep,
  setImportRecordsLength,
}: Props) => {
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);

  const pagination = usePagination(10);
  const navigate = useNavigate();
  const { perPage, currentPage } = pagination;

  const commissionImportRecords = useQuery<Page<CommissionImportRecords>, AxiosError>(
    [COMMISSION.COMMISSION_IMPORT_RECORDS, perPage, currentPage, sortBy],
    () =>
      getCommissionImport(currentPage, perPage, commissionImportId, decodeUriSortParams(sortBy)),
    { keepPreviousData: true }
  );

  const mutateAcceptCommissionImport = useMutation<AxiosResponse, AxiosError>(
    () => acceptCommissionImport(commissionImportId),
    {
      onSuccess: () => {
        setStep(3);
        setImportRecordsLength(commissionImportRecords?.data?.totalElements);
      },
    }
  );

  const removeCommissionImport = useMutation<AxiosResponse, AxiosError>(
    () => deleteCommissionImport(commissionImportId),
    {
      onSuccess: () => {
        refetchImports();
        navigate('..');
      },
    }
  );

  const handleSortBy = (sortBy: SortingRule<any>[]) => setSortBy(sortBy);

  const removeCommissionIsLoading = removeCommissionImport.isLoading;
  const mutateAcceptCommissionIsLoading = mutateAcceptCommissionImport.isLoading;

  return (
    <div className="space-y-8">
      {commissionImportRecords.isError && <ErrorMessages error={commissionImportRecords.error} />}
      {commissionImportRecords.isSuccess && (
        <Box.FullWidth>
          <Table
            isLoading={commissionImportRecords.isLoading}
            totalPages={commissionImportRecords.data.totalPages}
            pagination={pagination}
            data={commissionImportRecords.data.content}
            columns={columns}
            onSortBy={handleSortBy}
            sortBy={sortBy}
            totalElementsMessage={`Zaimportowano poprawnie z pliku ${
              commissionImportRecords?.data?.totalElements ?? 0
            } wierszy`}
          />
        </Box.FullWidth>
      )}

      <div className="w-full flex space-x-4 justify-end">
        {!commissionImportRecords.isError && (
          <>
            <Button
              isDisabled={mutateAcceptCommissionIsLoading}
              className="flex space-x-2"
              onPress={() => mutateAcceptCommissionImport.mutate()}>
              {mutateAcceptCommissionIsLoading && <Loader className="h-4 w-4 !border-white" />}
              <span>Akceptuj import</span>
            </Button>

            <Button
              isDisabled={removeCommissionIsLoading}
              className="flex space-x-2"
              onPress={() => removeCommissionImport.mutate()}>
              {removeCommissionIsLoading && <Loader className="h-4 w-4 !border-white" />}
              <span>Anuluj import</span>
            </Button>
          </>
        )}

        {(removeCommissionImport.isError || commissionImportRecords.isError) && (
          <Button onPress={() => navigate('..')}>Zakończ</Button>
        )}
      </div>

      {removeCommissionImport.isError && <ErrorMessages error={removeCommissionImport.error} />}
      {mutateAcceptCommissionImport.isError && (
        <ErrorMessages error={mutateAcceptCommissionImport.error} />
      )}
    </div>
  );
};

export default CommissionImportPreview;
