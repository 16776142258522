export type Role =
  | 'ADMIN'
  | 'COMMISSION'
  | 'REPORTS'
  | 'RECORDS_MANAGEMENT'
  | 'CONTRACTOR'
  | 'SUPER_ADMIN';

export enum UserType {
  COMMISSION_USER = 'COMMISSION_USER',
  PORTAL_USER = 'PORTAL_USER',
}

export enum Roles {
  ADMIN = 'ADMIN',
  REPORTS = 'REPORTS',
  CONTRACTOR = 'CONTRACTOR',
  COMMISSION = 'COMMISSION',
  SUPER_ADMIN = 'SUPER_ADMIN',
  RECORDS_MANAGEMENT = 'RECORDS_MANAGEMENT',
}

export type UserAction =
  | 'CHANGE_STATUS'
  | 'EDIT_DATA'
  | 'CHANGE_PASSWORD'
  | 'EDIT_ROLES'
  | 'CREATE_USER';

export interface User {
  agentCode: string;
  keycloakId: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  roles: Role[];
  password: string;
  lastLoginTime?: string;
}

export type AddUserPayload = Pick<User, 'username'> &
  Partial<Pick<User, 'agentCode' | 'email' | 'firstName' | 'lastName' | 'roles' | 'password'>>;

export type EditRolesPayload = Pick<User, 'roles'>;

export type EditUserPayload = Pick<User, 'firstName' | 'lastName' | 'email'>;

export interface ChangePasswordPayload {
  password: string;
  confirmPassword: string;
}
